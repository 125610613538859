import React from "react"
import { Button, Form, Input, notification } from "antd"

import { apiUrl } from "../../../config"

import "./contact-us.scss"

const FormCustom = () => {
  const [form] = Form.useForm()

  const sendMessage = async data => {
    console.log("data", data)
    try {
      const res = await fetch(`${apiUrl}relay/contactUs`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })

      if (res.status !== 201) {
        const json = await res.json()
        throw new Error(`${json.message}`)
      }
      form.resetFields()

      notification.success({ message: "Message has been successfully sent" })
    } catch (e) {
      notification.success({
        message: "Message has been successfully sent!",
        description: "Your request has been sent. We will contact you shortly.",
      })
      console.error("e")
    }
  }

  const onFinish = async values => {
    await sendMessage(values)
  }

  return (
    <Form onFinish={onFinish} layout="vertical" form={form} name="control-ref">
      <Form.Item
        className="antInputFrom"
        name="name"
        label="Name"
        placeholder="David"
        rules={[
          { required: true, min: 2, message: "Please type a valid name" },
        ]}
      >
        <Input />
        {/* <div className="requiredIcon" /> */}
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please type a valid email",
          },
        ]}
      >
        <Input label="Email" />
        {/* <div className="requiredIcon" /> */}
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone (optional)"
        rules={[
          {
            message: "Please input valid Phone!",
            pattern:
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
          },
        ]}
      >
        <Input type={"tel"} />
      </Form.Item>
      <Form.Item
        name="message"
        rules={[{ required: true, message: "Please type your message" }]}
        label="Message"
      >
        <Input.TextArea />
        {/* <div className="requiredIcon" /> */}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          SEND MESSAGE
        </Button>
      </Form.Item>
    </Form>
  )
}
export default FormCustom
