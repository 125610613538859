import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import { Layout } from "antd"

import ReactDOM from "react-dom"

import { scrollIntoView } from "../../utils/routes"
import { portalUrl } from "../../config"

import "./header.scss"

const navItems = [
  { id: "feature", title: "FEATURES" },
  { id: "userTestimonils", title: "TESTIMONIALS" },
  { id: "testDrive", title: "REQUEST A TRIAL" },
  { id: "pricing", title: "PRICING" },
  { id: "footer", title: "CONTACT US" },
]

const Header = () => {
  const menuRef = useRef(null)
  const menuBtnRef = useRef(null)

  const [isMenuActive, setIsMenuActive] = useState()

  const handleClickOutside = e => {
    const domNode = ReactDOM.findDOMNode(menuRef.current)
    const menuBtn = ReactDOM.findDOMNode(menuBtnRef.current)

    if (
      !!e.target &&
      !!domNode &&
      !domNode.contains(e.target) &&
      !menuBtn.contains(e.target)
    ) {
      setIsMenuActive(false)
    }
  }

  const handleClick = item => e => {
    e.preventDefault()
    scrollIntoView(item.id)()
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [])

  return (
    <Layout.Header className="header">
      <div className="header-wrapper">
        <img src="logo.png" className="header-logo" alt="logo" />
        <div
          className="menu-btn"
          ref={menuBtnRef}
          onClick={() => {
            setIsMenuActive(!isMenuActive)
          }}
        >
          <span className="line1"></span>
          <span className="line2"></span>
          <span className="line3"></span>
        </div>
        <nav
          className={isMenuActive ? "header-nav menuActive" : "header-nav"}
          ref={menuRef}
        >
          {navItems.map(item => (
            <a href="#" key={item.title} onClick={scrollIntoView(item.id)}>
              {item.title}
            </a>
          ))}
          <a href="#" onClick={scrollIntoView("footer")}>
            REQUEST A DEMO
          </a>
        </nav>
      </div>
    </Layout.Header>
  )
}

export default Header
