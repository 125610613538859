import React from "react"
import { Layout as AntLayout } from "antd"

import Header from "../Header"
import Footer from "../Footer"

import "../../styles/global.scss"

const Layout = ({ children }) => {
  const [isRendered, setIsRendered] = React.useState(false)

  React.useEffect(() => {
    setIsRendered(true)
  }, [])

  return (
    <AntLayout>
      <Header />
      <AntLayout.Content>{children}</AntLayout.Content>
      {isRendered && <Footer />}
    </AntLayout>
  )
}

export default Layout
