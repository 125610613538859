import React, { useEffect, useState } from "react"
import { Typography } from "antd"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Lottie from "react-lottie"

import {
  LayersOutline,
  TrainOutline,
  TimeOutline,
  DownloadOutline,
  HardwareChipOutline,
  BulbOutline,
  StatsChartOutline,
  ShieldOutline,
  PieChartOutline,
} from "react-ionicons"
import { Fade, LightSpeed, Roll } from "react-reveal"
import axios from "axios"

import { Layout, Button, InlineButton } from "../components"

import peopleCircleOutline from "../images/people-circle-outline.svg"
import ai from "../images/icons8ai.svg"
import analytics from "../images/analytics.svg"
import graphic from "../images/graphic.svg"
import nonBot from "../images/nonBot.svg"
import group from "../images/Group.svg"

import communication from "../images/icons8-communication.png"
import telegramSystem from "../images/telegramSystem.png"

import { scrollIntoView, changeLocation } from "../utils/routes"
import content from "../sources/content/homepage.json"
import shipping from "../sources/lottie/shipping.json"

import Carousel from "react-multi-carousel"

import "../styles/homepage.scss"
import { portalUrl } from "../config"
import { apiUrl } from "../config"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1279, min: 768 },
    items: 2,
  },
  phone: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
}

const stats = [
  { title: "Total Booked Loads", value: "321,463", icon: <LayersOutline /> },
  { title: "Total Booked Miles", value: "3,781,498", icon: <TrainOutline /> },
  { title: "Booked in a last 24h", value: "243", icon: <TimeOutline /> },
  { title: "Last Booking", value: "13m ago", icon: <DownloadOutline /> },
]

const features = [
  {
    title: "INSTANT <strong>AUTO BOOKING</strong>",
    description: content.features.autoBooking,
    icon: <HardwareChipOutline />,
    animationProps: { left: true },
  },
  {
    title: "STRONG <strong>ANALYTICS</strong>",
    description: content.features.analytics,
    icon: <BulbOutline />,
    animationProps: { right: true },
    className: "block__dark",
  },
  {
    title: "DETAILED <strong>STATISTICS</strong>",
    description: content.features.statistic,
    icon: <StatsChartOutline />,
    animationProps: { bottom: true },
    className: "block__dark",
  },
  {
    title: "TOTALLY <strong>SECURE</strong>",
    description: content.features.secure,
    icon: <ShieldOutline />,
    animationProps: { bottom: true },
  },
]

const pricing = [
  {
    size: "SMALL",
    price: "5000",
    commission: "3",
    percent: "3",
    driversCount: "9",
    maxCompanySize: "10",
  },
  {
    size: "MIDDLE",
    price: "7500",
    commission: "2",
    percent: "2",
    driversCount: "29",
    maxCompanySize: "50",
  },
  {
    size: "LARGE",
    price: "12500",
    commission: "1",
    percent: "1",
    driversCount: "9",
    maxCompanySize: null,
  },
]

const userTestimonials = [
  {
    message:
      "The Neotrucker has all the tools in its arsenal to automatically book loads. Our dispatchers work in and outside the United States and the software works like a clock in all locations.",
    author: "AJ Owner",
  },
  {
    message:
      "I have been driving truck for a very long time and after I started using The Neotrucker, my job has become much easier. It alerts me when it auto-books a new load so I don't get interrupted on the road",
    author: "J****n S. Owner operator.",
  },
  {
    message:
      " I think The Neotrucker is the next big thing like the Uber. The Neotrucker is going to boom the industry with its biggest revolution - auto-book. It is just unbelievable how much potential this thing has.",
    author: "I***n Dispatcher",
  },
]
const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li onClick={() => onClick()} className={active ? "dot active" : "dot"}>
      {" "}
    </li>
  )
}

const Home = () => {
  const [isRendered, setIsRendered] = useState(false)
  const [plans, setPlans] = useState([])

  useEffect(() => {
    ;(async () => {
      setIsRendered(true)
      await fetchPlans()
    })()
  }, [])

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${apiUrl}payments/plans`)
      setPlans(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Layout>
      <section className="section-main">
        <div className="main">
          <div className="the-problem">
            <div className="the-problem-wrapper">
              <Typography.Title
                level={3}
                className="main-description-block-title"
              >
                THE PROBLEM
              </Typography.Title>
              <Typography level={3} className="main-description-block-text">
                Most transportation companies use online Load Boards such as
                Amazon Relay Board that helps to increase the overall revenue.
                Searching and choosing the right load with the desired price,
                pickup, and delivery place is one of the significant problems of
                companies. These are long time-consuming and require extra
                workforce and effort. After all, above everything else lies
                revenue.
              </Typography>
            </div>
            <div className="main-media-gif">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: shipping,
                }}
              />
            </div>
          </div>
          <div className="the-solution">
            <div className="the-solution-wrapper">
              <Typography.Title
                level={3}
                className="main-description-block-title"
              >
                THE SOLUTION
              </Typography.Title>
              <Typography level={3} className="main-description-block-text">
                By providing an <span>AI intelligent</span> and fully automated
                platform - "neotrucker.com" to automate all your booking
                processes of your desired loads and provide{" "}
                <span>analytics</span> <span>statistics solutions</span>, you
                can better manage your fleet by organizing the most efficient
                and fast solutions.
              </Typography>
            </div>
            <div className="main-media-youtube">
              <iframe
                src="https://www.youtube.com/embed/eh-NbrObb40"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="main-description-form">
            <div className="main-description-form-btn">
              <Button onClick={scrollIntoView("footer")}>
                REQUEST A TRIAL
              </Button>
            </div>
            <div className="main-description-form-text">
              Try Neotrucker for 30 days for free
            </div>
          </div>
        </div>
        <a className="scroll-button" onClick={scrollIntoView("feature")}>
          <span></span>
        </a>
      </section>
      {isRendered && (
        <>
          <section className="section-feature feature" id="feature">
            <div className="feature-blocks">
              <div className="feature-block">
                <Fade left>
                  <div className="block">
                    <div className="block-wrapper">
                      <div className="block-content">
                        <p className="block-content-title">
                          {" "}
                          AUTOMATED <span>LOAD BOOKING</span>
                        </p>
                        <p className="block-content-text">
                          Neotrucker.com's "Automated Load Booking" doesn't only
                          automatically book loads on "Amazon Relay" but also
                          acts like a human and intelligently (AI) performs the
                          booking of the desired load, unlike some bot account.
                          Our team of specialists has made sure that the system
                          works just as dispatch personnel does not affect your
                          company's reputation.'
                        </p>
                        <div className="block-content-youtube">
                          <span className="more">Want more details?</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="24.004"
                            viewBox="0 0 32 24.004"
                            style={{ margin: "0px 10px" }}
                          >
                            <path
                              id="logo-youtube"
                              d="M31.788,69.3a4.859,4.859,0,0,0-4.626-5.076C23.7,64.063,20.168,64,16.559,64H15.434c-3.6,0-7.138.063-10.6.225A4.87,4.87,0,0,0,.213,69.313c-.156,2.225-.223,4.45-.219,6.675s.065,4.453.213,6.682a4.875,4.875,0,0,0,4.619,5.094c3.638.169,7.37.244,11.164.238q5.7.019,11.164-.238A4.876,4.876,0,0,0,31.78,82.67c.15-2.232.219-4.457.213-6.688q.021-3.338-.2-6.682ZM12.933,82.12V69.838L22,75.976Z"
                              transform="translate(0.006 -64)"
                              fill="#d31e1e"
                            />
                          </svg>
                          <span
                            onClick={changeLocation(
                              "https://www.youtube.com/watch?v=QJD7i6yiksk"
                            )}
                            className="url"
                          >
                            Watch on YouTube
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
                <img src={ai} alt="kek" />
              </div>

              <div className="feature-block">
                <Fade right>
                  <div className="block">
                    <div className="block-wrapper">
                      <div className="block-content">
                        <p className="block-content-title">
                          {" "}
                          STRONG <span>ANALYTICS</span>
                        </p>
                        <p className="block-content-text">
                          List of regularly recurring loads/ Analyzing
                          repetitive loads and their locations with average pay
                          and time when these loads posted with most or best
                          destinations, highest paid, and longest or shorts
                          destinations in Amazon relay regularly
                        </p>
                        <div className="block-content-youtube">
                          <span className="more">Want more details?</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="24.004"
                            viewBox="0 0 32 24.004"
                            style={{ margin: "0px 10px" }}
                          >
                            <path
                              id="logo-youtube"
                              d="M31.788,69.3a4.859,4.859,0,0,0-4.626-5.076C23.7,64.063,20.168,64,16.559,64H15.434c-3.6,0-7.138.063-10.6.225A4.87,4.87,0,0,0,.213,69.313c-.156,2.225-.223,4.45-.219,6.675s.065,4.453.213,6.682a4.875,4.875,0,0,0,4.619,5.094c3.638.169,7.37.244,11.164.238q5.7.019,11.164-.238A4.876,4.876,0,0,0,31.78,82.67c.15-2.232.219-4.457.213-6.688q.021-3.338-.2-6.682ZM12.933,82.12V69.838L22,75.976Z"
                              transform="translate(0.006 -64)"
                              fill="#d31e1e"
                            />
                          </svg>
                          <span
                            onClick={changeLocation(
                              "https://www.youtube.com/watch?v=r1BxmzAp14Q"
                            )}
                            className="url"
                          >
                            Watch on YouTube
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

                <img src={analytics} alt="kek" />
              </div>
              <div className="feature-block">
                <Fade left>
                  <div className="block statics">
                    <div className="block-wrapper">
                      <div className="block-content">
                        <p className="block-content-title kek">
                          <span>STATISTICS</span> OF COMPANIES{" "}
                        </p>

                        <p className="block-content-text">
                          The average amount of load from beginning of a year in
                          the correct chart with a diagram to simplify to
                          understand of the followings:
                          <ul>
                            <li>
                              Your company's Total Booked Loads and Miles, loads
                              booked in last 24, and time from the previous load
                              your company completed.
                            </li>
                            <li>
                              Top Loads and Average Load Payout and Rates charts
                              of your company
                            </li>
                            <li>
                              Top Performing companies and the Latest Booked
                              loads by others
                            </li>
                          </ul>
                        </p>
                        <div className="block-content-youtube">
                          <span className="more">Want more details?</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="24.004"
                            viewBox="0 0 32 24.004"
                            style={{ margin: "0px 10px" }}
                          >
                            <path
                              id="logo-youtube"
                              d="M31.788,69.3a4.859,4.859,0,0,0-4.626-5.076C23.7,64.063,20.168,64,16.559,64H15.434c-3.6,0-7.138.063-10.6.225A4.87,4.87,0,0,0,.213,69.313c-.156,2.225-.223,4.45-.219,6.675s.065,4.453.213,6.682a4.875,4.875,0,0,0,4.619,5.094c3.638.169,7.37.244,11.164.238q5.7.019,11.164-.238A4.876,4.876,0,0,0,31.78,82.67c.15-2.232.219-4.457.213-6.688q.021-3.338-.2-6.682ZM12.933,82.12V69.838L22,75.976Z"
                              transform="translate(0.006 -64)"
                              fill="#d31e1e"
                            />
                          </svg>
                          <span
                            onClick={changeLocation(
                              "https://www.youtube.com/watch?v=m815hX5uF9c"
                            )}
                            className="url"
                          >
                            Watch on YouTube
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

                <img src={graphic} alt="kek" />
              </div>
              <div className="feature-block">
                <Fade right>
                  <div className="block safety">
                    <div className="block-wrapper">
                      <div className="block-content">
                        <p className="block-content-title">
                          <span>SAFETY</span> AND <span>SECURITY</span>
                        </p>

                        <p className="block-content-text">
                          Certificates of reputable American organizations
                          provide Safety and Security and fully encrypt all of
                          the traffics and connections, making it almost
                          impossible to interrupt or steal your data from our
                          platform.
                        </p>
                        <div className="block-content-youtube">
                          <span className="more">Want more details?</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="24.004"
                            viewBox="0 0 32 24.004"
                            style={{ margin: "0px 10px" }}
                          >
                            <path
                              id="logo-youtube"
                              d="M31.788,69.3a4.859,4.859,0,0,0-4.626-5.076C23.7,64.063,20.168,64,16.559,64H15.434c-3.6,0-7.138.063-10.6.225A4.87,4.87,0,0,0,.213,69.313c-.156,2.225-.223,4.45-.219,6.675s.065,4.453.213,6.682a4.875,4.875,0,0,0,4.619,5.094c3.638.169,7.37.244,11.164.238q5.7.019,11.164-.238A4.876,4.876,0,0,0,31.78,82.67c.15-2.232.219-4.457.213-6.688q.021-3.338-.2-6.682ZM12.933,82.12V69.838L22,75.976Z"
                              transform="translate(0.006 -64)"
                              fill="#d31e1e"
                            />
                          </svg>
                          <span
                            onClick={changeLocation(
                              "https://www.youtube.com/watch?v=4tX56BkwUSw"
                            )}
                            className="url"
                          >
                            Watch on YouTube
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

                <img src={group} alt="kek" />
              </div>
              <div className="feature-block">
                <Fade left>
                  <div className="block nonBot">
                    <div className="block-wrapper">
                      <div className="block-content">
                        <p className="block-content-title">
                          NON-BOT <span>ACCOUNT</span>
                        </p>
                        <p className="block-content-text">
                          "neotrucker.com" never works as a BOT (robotic)
                          account. It uses your credentials from existing load
                          boards and logs in and books the desired load as a
                          human. Thus, never does the system interrupt your
                          service contract with Load Boards such as "Amazon
                          Relay" and always avoids suspicions at all costs.
                        </p>
                        <div className="block-content-youtube">
                          <span className="more">Want more details?</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="24.004"
                            viewBox="0 0 32 24.004"
                            style={{ margin: "0px 10px" }}
                          >
                            <path
                              id="logo-youtube"
                              d="M31.788,69.3a4.859,4.859,0,0,0-4.626-5.076C23.7,64.063,20.168,64,16.559,64H15.434c-3.6,0-7.138.063-10.6.225A4.87,4.87,0,0,0,.213,69.313c-.156,2.225-.223,4.45-.219,6.675s.065,4.453.213,6.682a4.875,4.875,0,0,0,4.619,5.094c3.638.169,7.37.244,11.164.238q5.7.019,11.164-.238A4.876,4.876,0,0,0,31.78,82.67c.15-2.232.219-4.457.213-6.688q.021-3.338-.2-6.682ZM12.933,82.12V69.838L22,75.976Z"
                              transform="translate(0.006 -64)"
                              fill="#d31e1e"
                            />
                          </svg>
                          <span
                            onClick={changeLocation(
                              "https://www.youtube.com/watch?v=BnsoMqW9Rfo"
                            )}
                            className="url"
                          >
                            Watch on YouTube
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

                <img src={nonBot} alt="kek" />
              </div>
            </div>
          </section>
          <section className="userTestimonils" id="userTestimonils">
            <div className="userTestimonils-container">
              {" "}
              <Typography.Title level={3} className="userTestimonils-title">
                Neotrucker <span>User Testimonials</span>
              </Typography.Title>
              <Typography level={2} className="userTestimonils-sub-title">
                We always work to bring better performance to our product.
              </Typography>
              <Carousel
                responsive={responsive}
                showDots
                showArrow={false}
                className="carosel-container"
                customDot={<CustomDot />}
              >
                {userTestimonials.map((userTestimonial, index) => (
                  <div className="userTestimonial-card" key={index}>
                    <div className="userTestimonial-card-wrapper">
                      <img
                        src={communication}
                        className="userTestimonial-card-wrapper-img"
                      />
                      <p className="userTestimonial-card-wrapper-message">
                        {userTestimonial.message}
                      </p>
                    </div>
                    <p className="userTestimonial-card-author">
                      {userTestimonial.author}
                    </p>
                  </div>
                ))}
              </Carousel>
            </div>
          </section>
          <section className="community" id="commutnity">
            <LightSpeed right cascade>
              <div className="join">
                <Typography.Title level={3} className="join-title">
                  Join To Our <span>Community</span>
                </Typography.Title>
                <Typography level={2} className="join-sub-title">
                  Get the latest news, communicate, ask questions, submit your
                  ideas together with other users of our service
                </Typography>
                <div className="join-telegram-buttons">
                  <div className="buttons-wrapper">
                    <Button
                      onClick={changeLocation("https://t.me/neotrucker")}
                      className="button-telegram"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18.195"
                        viewBox="0 0 20 18.195"
                      >
                        <path
                          id="icons8-telegram-app"
                          d="M22.622,6.252a1.394,1.394,0,0,0-1.431-.108h0C20.607,6.378,4.7,13.2,4.048,13.482c-.118.041-1.147.425-1.041,1.28a1.547,1.547,0,0,0,1.023,1.127l4.045,1.385c.268.893,1.258,4.189,1.477,4.894.136.439.359,1.016.749,1.134a.93.93,0,0,0,.9-.161l2.473-2.294,3.992,3.114.1.057a1.922,1.922,0,0,0,.779.18,1.441,1.441,0,0,0,.551-.108,1.669,1.669,0,0,0,.862-.88l2.982-15.5A1.374,1.374,0,0,0,22.622,6.252ZM11.642,17.828l-1.365,3.639L8.913,16.918,19.375,9.185Z"
                          transform="translate(-2.999 -6.002)"
                          fill="#ffffff"
                        />
                      </svg>

                      <p className="button-telegram-text">
                        Join Telegram Group
                      </p>
                    </Button>
                    <Button
                      onClick={changeLocation("https://t.me/NeoTruckers")}
                      className="button-telegram dashed"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18.195"
                        viewBox="0 0 20 18.195"
                      >
                        <path
                          id="icons8-telegram-app"
                          d="M22.622,6.252a1.394,1.394,0,0,0-1.431-.108h0C20.607,6.378,4.7,13.2,4.048,13.482c-.118.041-1.147.425-1.041,1.28a1.547,1.547,0,0,0,1.023,1.127l4.045,1.385c.268.893,1.258,4.189,1.477,4.894.136.439.359,1.016.749,1.134a.93.93,0,0,0,.9-.161l2.473-2.294,3.992,3.114.1.057a1.922,1.922,0,0,0,.779.18,1.441,1.441,0,0,0,.551-.108,1.669,1.669,0,0,0,.862-.88l2.982-15.5A1.374,1.374,0,0,0,22.622,6.252ZM11.642,17.828l-1.365,3.639L8.913,16.918,19.375,9.185Z"
                          transform="translate(-2.999 -6.002)"
                          fill="#29b6f6"
                        />
                      </svg>

                      <p className="button-telegram-text">
                        Subscribe Telegram Channel
                      </p>
                    </Button>
                  </div>
                  <span>OR</span>
                  <Button
                    onClick={changeLocation(
                      "https://neotrucker.eventbrite.com"
                    )}
                    className="button-telegram subsc-btn-for-news"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="15.714"
                      viewBox="0 0 20 15.714"
                    >
                      <path
                        id="mail"
                        d="M49.5,80h-15A2.5,2.5,0,0,0,32,82.5V93.214a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5V82.5A2.5,2.5,0,0,0,49.5,80Zm-.633,4.135-6.429,5a.714.714,0,0,1-.877,0l-6.429-5a.714.714,0,1,1,.877-1.128L42,87.667l5.99-4.659a.714.714,0,1,1,.877,1.128Z"
                        transform="translate(-32 -80)"
                        fill="#fff"
                      />
                    </svg>

                    <p className="button-telegram-text">
                      Subscribe For News and Webinars
                    </p>
                  </Button>
                </div>
              </div>
            </LightSpeed>

            <img src={telegramSystem} alt="kek" className="telegram-galaxy" />
          </section>
          <section className="testDrive" id="testDrive">
            <StaticImage
              src="../images/screen.png"
              alt="A dinosaur"
              placeholder="blurred"
              layout="fixed"
              className="screen"
            />
            <LightSpeed right cascade>
              <div className="info">
                <p className="title">Do you want a test drive?</p>
                <p className="description">{content.testDrive}</p>
                <Link to={portalUrl}>
                  Try NEOTRUCKER for 30 Days. No credit card needed!
                </Link>
                <Button onClick={scrollIntoView("footer")}>
                  REQUEST A TRIAL
                </Button>
              </div>
            </LightSpeed>
          </section>
          <section className="section-pricing" id="pricing">
            <div className="pricingWrapper">
              <p className="title">
                <span>NEOTRUCKER</span> Pricing
              </p>
              <div className="pricingSelects">
                {pricing
                  .sort((a, b) => a.price - b.price)
                  .map(({ name, price, percent, maxCompanySize }) => (
                    <Roll bottom>
                      <div className="pricingSelect">
                        <p className="pricingSelectTitle">{name}</p>
                        <div className="pricingSelectPriceWrapper">
                          <div className="pricingSelectPrice">
                            <span className="symbol">$</span>
                            <span className="price">{price / 100}</span>
                            <span className="period">/month</span>
                          </div>
                        </div>
                        <div className="infoTemplate">
                          <PieChartOutline />
                          <div className="infoTemplateWrapper">
                            <p className="title">Commission</p>
                            <p className="value">{percent}%</p>
                          </div>
                        </div>
                        <div className="infoTemplate">
                          <img src={peopleCircleOutline} alt="people" />
                          {/* <PeopleCircleOutlined /> */}
                          <div className="infoTemplateWrapper">
                            <p className="title">Users count</p>
                            <p className="value">
                              {maxCompanySize === null
                                ? "unlimited"
                                : `up to ${maxCompanySize} drivers`}
                            </p>
                          </div>
                        </div>
                        <InlineButton
                          href="#"
                          className="selectBtn"
                          onClick={scrollIntoView("footer")}
                        >
                          SELECT
                        </InlineButton>
                      </div>
                    </Roll>
                  ))}
              </div>
            </div>
          </section>
        </>
      )}
      <svg
        style={{
          visibility: "hidden",
          position: "absolute",
          boxShadow: "0px 3px 20px #0000000d",
        }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </Layout>
  )
}

export default Home
