import React from "react"
import { Layout } from "antd"
import Bounce from "react-reveal/Bounce"
import { Link } from "gatsby"
import {
  LogoTwitter,
  LogoInstagram,
  LogoYoutube,
  LogoFacebook,
  CallOutline,
  MailOutline,
} from "react-ionicons"

import { Modal } from "antd"
import Form from "./ContactUs"
import "./footer.scss"
import LogoTelegram from "../../images/telegram.svg"

import documents from "../../sources/content/footer.json"
import { clearConfigCache } from "prettier"

const socialLinks = [
  {
    name: "twitter",
    link: "https://twitter.com/neotrucker",
    icon: <LogoTwitter />,
    urlText: "twitter.com/neotrucker",
  },
  {
    name: "instagram",
    link: "https://instagram.com/neotruckers?utm_medium=copy_link",
    icon: <LogoInstagram />,
    urlText: "instagram.com/neotrucker",
  },
  {
    name: "facebook",
    link: "https://facebook.com/neotrucker",
    icon: <LogoFacebook />,
    urlText: "facebook.com/neotrucker",
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCD9Dd1Y7ymzDSYu2u-JXtXA/featured",
    icon: <LogoYoutube />,
    urlText: "youtube.com/neotrucker",
  },
  {
    name: "telegram",
    link: "http://t.me/NeoTruckers",
    urlText: "t.me/NeoTruckers",
    icon: (
      <img
        src={LogoTelegram}
        alt="tg"
        style={{ marginRight: "20px", height: "22px" }}
      />
    ),
  },
]

const contacts = [
  {
    link: "tel:1-725-202-6726",
    icon: <CallOutline />,
    label: "Sales and Marketing: ",
  },
  {
    link: "tel:1-725-202-6860",
    icon: <CallOutline />,
    label: "Contact to Support: ",
  },
  {
    link: "mailto:sales@neotrucker.co",
    icon: <MailOutline />,
    label: "Information:",
  },
  {
    link: "mailto:support@neotrucker.com",
    icon: <MailOutline />,
    label: "Support:",
  },
  {
    link: "mailto:marketing@neotrucker.com",
    icon: <MailOutline />,
    label: "Marketing:",
  },
]

const helpfulLinks = [
  { title: "Privacy Policy", link: "/404" },
  { title: "Terms of use" },
  { title: "Amazon Relay", link: "https://relay.amazon.com/" },
  {
    title: "Subscribe For News and Webinars and Events",
    link: "https://neotrucker.eventbrite.com",
  },
]

const Footer = () => {
  const [isVisibleTermsOfUse, setIsVisibleTermsOfUse] = React.useState()
  const [isVisiblePrivacyPolicy, setIsVisiblePrivacyPolicy] = React.useState()
  return (
    <Layout.Footer className="footer" id="footer">
      <div className="footer-wrapper">
        <div className="footer-contacts">
          <div className="section">
            <span>Social media</span>
            {socialLinks.map(({ name, icon, link, urlText }) => (
              <Link key={name} to={`${link}`} className="section-item">
                {icon}
                <p className="urlText">{urlText}</p>
              </Link>
            ))}
          </div>
          <div className="section">
            <span>Contacts</span>
            {contacts.map(({ link, icon, label }) => (
              <Link href={link} key={link} className="section-item link">
                {icon}
                <p className="section-item urlText">
                  {!!label && label + " "}
                  {link.split(":")[1]}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <div className="section">
          <span>Links</span>
          {helpfulLinks.map(({ title, link }) => {
            console.log("kek", title.includes("Terms of use"))
            if (title.includes("Privacy Policy")) {
              return (
                <React.Fragment>
                  <a
                    className="urlText"
                    key={title}
                    target="_blank"
                    onClick={() => setIsVisiblePrivacyPolicy(true)}
                  >
                    {title}
                  </a>
                  <Modal
                    title="Privacy Policy"
                    visible={isVisiblePrivacyPolicy}
                    okButtonProps={{ style: { display: "none" } }}
                    cancelButtonProps={{
                      type: "primary",
                    }}
                    onCancel={() => setIsVisiblePrivacyPolicy(false)}
                    className="term-of-use-modal"
                  >
                    <div className="terms-of-use">
                      {documents.privacyPolicy.map(privacy => {
                        return (
                          <React.Fragment>
                            {privacy.hasOwnProperty("title") && (
                              <h2>{privacy.title}</h2>
                            )}
                            {privacy.hasOwnProperty("content") &&
                              privacy.content.map(text => {
                                if (text.hasOwnProperty("list")) {
                                  console.log(text)
                                  return (
                                    <ul
                                      className={
                                        text.list.decorType === "line"
                                          ? "dashed1"
                                          : undefined
                                      }
                                    >
                                      {text.list.content.map(tx => (
                                        <li>{tx}</li>
                                      ))}
                                    </ul>
                                  )
                                }
                                return <p>{text}</p>
                              })}
                          </React.Fragment>
                        )
                      })}
                      <div className="contact-us">
                        <div className="contact-us-wrapper">
                          <p>Verumsofts LLC </p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>4730 South Fort Apache Rd, Suite 300 </p>
                          <p>Las Vegas, Nevada 89147</p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>Email Address: </p>
                          <p>info@verumsofts.com</p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>Telephone number: </p>
                          <p>702-703-2110</p>
                        </div>

                        <p>Effective as of January 31, 2022 </p>
                      </div>
                    </div>
                  </Modal>
                </React.Fragment>
              )
            }
            if (title.includes("Terms of use")) {
              console.log("if kek")
              return (
                <React.Fragment>
                  <a
                    className="urlText"
                    key={title}
                    target="_blank"
                    onClick={() => setIsVisibleTermsOfUse(true)}
                  >
                    {title}
                  </a>
                  <Modal
                    title="Terms of use"
                    visible={isVisibleTermsOfUse}
                    okButtonProps={{ style: { display: "none" } }}
                    cancelButtonProps={{
                      type: "primary",
                    }}
                    onCancel={() => setIsVisibleTermsOfUse(false)}
                    className="term-of-use-modal"
                  >
                    <div className="terms-of-use">
                      {documents.termsOfUse.map(term => {
                        return (
                          <React.Fragment>
                            <h2>{term.title}</h2>
                            {term.content.map(termContent => (
                              <p>{termContent}</p>
                            ))}
                          </React.Fragment>
                        )
                      })}
                      <div className="contact-us">
                        <div className="contact-us-wrapper">
                          <p>Verumsofts LLC </p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>P O Box 27740</p>
                          <p>Las Vegas, Nevada 89126 </p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>Email Address: </p>
                          <p>info@neotrucker.com </p>
                        </div>
                        <div className="contact-us-wrapper">
                          <p>Telephone number: </p>
                          <p>7027032110</p>
                        </div>

                        <p>Effective as of _________________ </p>
                      </div>
                    </div>
                  </Modal>
                </React.Fragment>
              )
            }
            return (
              <Link to={link} className="urlText" key={title} target="_blank">
                {title}
              </Link>
            )
          })}
        </div>
        <Bounce bottom>
          <div className="form">
            <p className="form-title">
              We will be glad to answer any of your questions!
            </p>
            <Form />
          </div>
        </Bounce>
      </div>
    </Layout.Footer>
  )
}
export default Footer
