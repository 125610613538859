export const scrollIntoView = targetId => e => {
  if (e) e.preventDefault()

  document
    .getElementById(targetId)
    .scrollIntoView({ block: "start", behavior: "smooth" })
}

export const changeLocation = link => () => {
  window.location = link
}
