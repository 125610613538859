import React from "react"
import cn from "classnames"

import "./button.scss"

export const Button = ({ children, className, ...props }) => {
  return (
    <button className={cn("button", { [className]: !!className })} {...props}>
      {children}
    </button>
  )
}

export const InlineButton = ({ children, className, ...props }) => {
  return (
    <a
      href="#"
      className={cn("inline-button", { [className]: !!className })}
      {...props}
    >
      {children}
    </a>
  )
}
